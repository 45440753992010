import sensors from 'sa-sdk-javascript';
// @ts-ignore
import customEventsSender from 'sa-sdk-javascript/dist/web/plugin/custom-events-sender/index.es6.js';

import Exposure from '../static/activity/assets/js/index.es6.js';
const uToken = useMiddleCookie('utoken');
const getInLet = () => {
  const search = window.location.search;
  return search.indexOf('enter=xcs') > -1 ? 'xcs' : 'df';
};

// 获取平台类型
const getPlatFormType = () => {
  const userAgent = navigator.userAgent;
  const isMobile = /Mobile/i.test(userAgent);
  const isTablet = /iPad/i.test(userAgent);
  if (isTablet) {
    return 'Pad';
  }
  if (isMobile) {
    return 'Mobile';
  }
  return 'PC';
};

export default defineNuxtPlugin(() => {
  sensors.init({
    server_url: import.meta.env.VITE_SENSORS_SERVERURl, // 上报地址
    heatmap: {
      clickmap: 'default', // $WebClick事件
      scroll_notice_map: 'default', // $WebStay事件
      custom_property: function (element_target: any) {
        // 标签的点击事件增加自定义属性 button-name,当元素没有文本内容时添加做埋点标识
        if (element_target.getAttribute('button-name')) {
          return {
            'button_name': element_target.getAttribute('button-name'),
          };
        }
      },
    },
    cross_subdomain: false, // 是否支持跨子域
    is_track_single_page: false, // 是否为单页面
    use_client_time: true,
    // show_log: import.meta.env.VITE_PASSPORT_ENV !== 'prod', // 控制台是否展示log
    show_log: false, // 控制台是否展示log - 可以查看 预置属性 等信息
    send_type: 'ajax',
  });
  sensors.registerPage({
    inLet: getInLet(),
    login_status: !!uToken.value, // 是否已登录
    platform_type: getPlatFormType(), // 平台类型
    item_type: 'community', // 社区标记
  });

  sensors.use(customEventsSender, (options: any) => {
    if (options.event_name.indexOf('ArtiMind') > -1) {
      return [import.meta.env.VITE_ARTIMIND_SENSORS_SERVERURl];
    }

    return [options.server_url];
  });
  sensors.use('PageLeave', {}); // pageLeave事件
  sensors.use('PageLoad', {}); // pageLoad事件
  sensors.use(Exposure, {
    area_rate: 0.5,
    stay_duration: 0,
    repeated: true,
  });
  sensors.quick('autoTrack'); // 开启全埋点 首次触发页面加载事件 $pageview,设置之后页面url切换自动触发

  if (location.pathname === '/premium') {
    // 判断是否访问过付费首页
    (globalThis as any).premiumVisited = true;
  }

  return {
    provide: {
      sensors,
    },
  };
});
