import type { SubscriptionPlan } from '~/components/subscription/constant';
import type { BenefitTy } from '~/types';

export const getMyDfBenefitsApi = defineFindApi({
  url: '/community/v1/web/my/subscription/entitlements',
  method: HttpMethod.GET,
  respTy: RespTy.Json<{
    type: SubscriptionPlan;
    entitlements: {
      type: BenefitTy;
      name: string;
      value: number;
      used: number;
    }[];
  }>(),
});

// 消费权益解锁案例
export const unlockMakingByBenefitApi = defineFindApi({
  url: '/community/v1/web/subscription/consume/making/:id',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json<{
    id: number;
  }>(),
});
