<template>
  <div class="error-page">
    <a-row justify="center">
      <a-col flex="675px">
        <div class="error-img">
          <img src="~/assets/img/error.png" alt="" />
        </div>

        <div v-if="message" class="error-message font-semibold">
          {{ message }}
        </div>

        <div class="error-description">{{ description }}</div>

        <div class="go-back">
          <df-btn class="go-back-btn" type="default" shape="default" :href="redirectUrl">
            {{ redirectText }}
          </df-btn>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script setup lang="ts">
const message = ref("Oops! We couldn't find the page you were looking for.");
const description = ref('Please double-check the URL and try again, or return to our homepage to continue browsing.');

const redirectUrl = ref('/');
const redirectText = ref('Go to Homepage');

const error = useError();

console.log(error.value);

if (error.value) {
  const err = error.value as {
    statusMessage: string;
    data: string;
  };
  message.value = err.statusMessage?.startsWith('Page not found') ? '' : err.statusMessage;

  if (err.data) {
    let parsedData = typeof err.data === 'string' ? JSON.parse(err.data) : err.data;

    description.value = parsedData.description;
    redirectUrl.value = parsedData.redirectUrl;
    redirectText.value = parsedData.redirectText;
  }
}
</script>

<style lang="less" scoped>
.error-page {
  height: 100vh;
  padding-top: 160px;
  background: #f4f4f4;

  .error-img {
    text-align: center;
  }
  .error-message {
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    text-align: center;
    margin-top: 8px;
  }

  .error-description {
    font-size: 16px;
    line-height: 24px;
    color: #999999;
    text-align: center;
    margin-top: 8px;
  }

  .go-back {
    text-align: center;
    margin-top: 16px;
  }
}
</style>
