import type { Nilable } from '~/types/fns';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:created', (app) => {
    let _redirect: Nilable<string> = null;
    const router = app.$nuxt.vueApp.config.globalProperties.$router;
    router.$refresh = (redirect: Nilable<string> = null) => {
      _redirect = redirect;
      router.replace('/refresh');
    };
    router.addRoute({
      path: '/refresh',
      name: 'refresh',
      component: defineComponent({
        name: 'Refresh',
        async beforeRouteEnter(to, from, next) {
          setTimeout(async () => {
            const nuxtApp = getNuxtApp();
            if (nuxtApp) {
              await nuxtApp.$router.replace(_redirect ?? from);
            }
            _redirect = null;
          }, 50);
          next();
        },
        setup() {
          return () => h('div', 'Refreshing...');
        },
      }),
    });
  });
});
