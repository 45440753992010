import { LoginBanner } from '#components';
import PassportClient, { AREA, CLIENT_ENV, DOMAIN_SPACE, PAGE_MODE, XTOOL_PLATFORM, type DefaultConfigType } from '@makeblock/passport-client';
import { isFunction, isNil } from 'lodash-es';
import { createApp } from 'vue';
import { useUserInfo } from '~/stores/user';
import { SENSORS_EVENT } from '~/types';

export default defineNuxtPlugin(() => {
  const config: DefaultConfigType = {
    domainSpace: DOMAIN_SPACE.XTOOL,
    platform: XTOOL_PLATFORM.DESIGN_FIND,
    mode: PAGE_MODE.WIN,
    lang: 'en',
    area: AREA.US,
    height: 700,
    preFetch: true,
    adaptWindow: true,
  };
  if (import.meta.client) {
    const div = document.createElement('div');
    const vm = createApp(LoginBanner);
    vm.mount(div);
    config.advertisement = div;
    config.advertisementPosition = 'left' as any;
  }
  const client = new PassportClient(config);
  if (import.meta.env.VITE_PASSPORT_ENV === 'prod') {
    client.env = CLIENT_ENV.PROD;
    client.lang = 'en';
    client.area = AREA.US;
  } else if (import.meta.env.VITE_PASSPORT_ENV === 'test') {
    client.env = CLIENT_ENV.TEST;
    client.lang = 'zh';
    client.area = AREA.CN;
  } else if (import.meta.env.VITE_PASSPORT_ENV === 'testus') {
    client.env = CLIENT_ENV.TEST;
    client.lang = 'en';
    client.area = AREA.US;
  } else {
    client.env = CLIENT_ENV.DEV;
    client.lang = 'zh';
    client.area = AREA.CN;
  }

  const proxyClient = new Proxy(client, {
    get(target, key) {
      const value = Reflect.get(target, key);
      if (isFunction(value)) {
        return (...args: any) => {
          return key !== 'openModal' ? Reflect.apply(value, target, args) : login(target, ...args);
        };
      } else {
        return value;
      }
    },
    set(target, key, value) {
      return Reflect.set(target, key, value);
    },
  });

  return {
    provide: {
      client: proxyClient,
    },
  };
});

let isListening = false;
function login(client: PassportClient, ...args: any) {
  const nuxtApp = getNuxtApp();
  if (isNil(nuxtApp)) {
    throw new Error('nuxtApp is null');
  }
  if (!isInClient()) {
    throw new Error('not in client');
  }

  const { $sensors } = nuxtApp;
  if (!isListening) {
    // 监听登录弹窗关闭事件
    window.addEventListener('message', (event) => {
      if (event.data?.action === 'close-passport-modal' && event.data?.reason === 'click-close-btn') {
        $sensors.track(SENSORS_EVENT.LOGIN_POP_CLOSE);
      }
    });
    isListening = true;
  }
  $sensors.track(SENSORS_EVENT.LOGIN_POP_SHOW);

  const user = useUserInfo();
  user.$reset();
  const channel = new BroadcastChannel('login_status');
  channel.postMessage({ data: 'logout' });

  Reflect.apply(client.openModal, client, args);
}
