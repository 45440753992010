import type { ThemeConfig } from 'ant-design-vue/es/config-provider/context';

export const antThemeConfig: ThemeConfig = {
  token: {
    colorPrimary: '#1A1A1A',
    fontFamily: 'Open Sans, Arial, Helvetica Neue, sans-serif, Noto Sans',
  },
  components: {
    Checkbox: {
      colorPrimary: '#1A1A1A',
      colorPrimaryHover: '#1A1A1A',
    },
    Spin: {
      colorPrimary: '#FF0035',
    },
    Cascader: {
      colorHighlight: '#FF7C23',
      controlItemBgActive: '#E9EAF0',
    },
    Select: {
      colorHighlight: '#FF7C23',
      controlItemBgActive: '#E9EAF0',
      controlOutline: 'rgba(26, 26, 26, .3)',
    },
    Input: {
      controlOutline: 'rgba(26, 26, 26, .3)',
    },
    DatePicker: {
      controlItemBgActive: '#E9EAF0',
      colorPrimary: '#575757',
    },
  },
};
