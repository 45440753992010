import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const isLocalhost = window.location.hostname === 'localhost';
  if (!isLocalhost) {
    const { $router } = useNuxtApp();
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: import.meta.env.VITE_TRACE_DSN, // 设置Sentry使用的数据源名称 (DSN)，用于将捕获的错误和事件发送到Sentry服务器
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation($router),
          tracingOrigins: [new RegExp(import.meta.env.VITE_TRACE_URL)], // Sentry收集的追踪数据中的URL来源
        }),
      ],
      tracesSampleRate: 1.0, // 将捕获 traces（性能数据）的采样率设置为 100%
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
});
