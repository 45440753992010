// keep-alive设置
const includes = ['featured', 'collection', 'column']; // featured-首页, collection-集合页
const excludes = [''];

const keepAlive = {
  include: includes.join(','),
  exclude: excludes.join(','),
};
const max = 10;

export { keepAlive, max };
