// 图片懒加载指令，
// 图片初始默认src为本地loading图片（...todo）
// 使用方式 <img v-lazy="xxxx""  />

import { isEmpty, isObject } from 'lodash-es';
import type { Directive } from 'vue';
import LOADING from '~/assets/img/common/loading.gif';

interface LazyImgElement extends HTMLImageElement {
  interested: boolean;
}
export interface LazyImgOptions {
  src: string;
  fallback?: string;
  disabled?: boolean;
}
export type LazyImgBindingValue = string | LazyImgOptions;

export const Lazy: Directive<LazyImgElement, LazyImgBindingValue> = {
  mounted(el, binding) {
    // 如果不是图片的话就不触发懒加载
    if (!(el instanceof HTMLImageElement)) {
      return;
    }
    const bindingValue = normalize(binding.value);
    if (isEmpty(bindingValue.src)) {
      console.warn('[v-lazy]: src is empty');
    }
    if (bindingValue.disabled) {
      el.src = bindingValue.src;
    } else {
      el.src = bindingValue.fallback ?? LOADING;
      el.interested = false;
      const observer = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          el.src = bindingValue.src;
          el.interested = true;
          observer.unobserve(el);
        }
      });
      observer.observe(el);
    }
  },
  updated(el, binding) {
    const bindingValue = normalize(binding.value);
    if ((el.interested || bindingValue.disabled) && bindingValue.src !== el.src) {
      el.src = bindingValue.src;
    }
  },
  getSSRProps(binding) {
    const bindingValue = normalize(binding.value);
    return {
      loading: 'lazy',
      src: bindingValue.disabled ? bindingValue.src : (bindingValue.fallback ?? LOADING),
    };
  },
};

function normalize(value: LazyImgBindingValue) {
  return isObject(value) ? value : { src: value };
}
