import type { Fn } from '~/types/fns';
import type { Directive } from 'vue';
import { isFunction, isNil } from 'lodash-es';

export interface InfiniteScrollElement extends HTMLElement {
  __V_INFINITE_SCROLL__: {
    cleanup: () => void;
  };
}
export type LoadFn = Fn<[], Promise<void>>;
export interface InfiniteScrollOptions {
  infiniteLoad: LoadFn;
  keepMinHeight?: boolean;
}

export type InfiniteScrollBindingValue = InfiniteScrollOptions | LoadFn;

export const InfiniteScroll: Directive<InfiniteScrollElement, InfiniteScrollBindingValue> = {
  mounted(el, binding) {
    const bindingValue = normalize(binding.value);
    if (!isFunction(bindingValue.infiniteLoad)) {
      console.warn(`[vInfiniteScroll]: ${bindingValue.infiniteLoad} is not a function`);
      return;
    }
    let loading = false;
    const onInfiniteScroll = async () => {
      const disabled = el.getAttribute('infinite-scroll-disabled') === 'true';
      if (isNil(el) || disabled || !isElementMounted(el)) {
        return;
      }
      if (bindingValue.keepMinHeight) {
        keepMinHeight(el);
      }
      if (!loading && checkLoad(el)) {
        loading = true;
        const [, err] = await tryAsyncCatch(bindingValue.infiniteLoad);
        if (!isNil(err)) {
          cleanup();
        }
        loading = false;
      }
    };
    const cleanup = () => {
      document.body.removeEventListener('scroll', onInfiniteScroll);
    };
    document.addEventListener('scroll', onInfiniteScroll);
    el.__V_INFINITE_SCROLL__ = {
      cleanup,
    };
    onInfiniteScroll();
  },
  unmounted(el) {
    el.__V_INFINITE_SCROLL__.cleanup();
  },
};

const isElementMounted = (el: HTMLElement) => {
  return el && el.offsetParent !== null;
};

const checkLoad = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return rect.bottom < window.innerHeight * 2;
};

const keepMinHeight = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  if (rect.top < window.innerHeight && rect.top > 0) {
    const d = window.innerHeight - rect.top;
    el.style.minHeight = `${d}px`;
  }
};

const normalize = (value: InfiniteScrollBindingValue) => {
  if (isFunction(value)) {
    return {
      infiniteLoad: value,
    };
  } else {
    return value;
  }
};
