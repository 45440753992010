import { defineStore } from 'pinia';
import { getMyDfBenefitsApi } from '~/api/benefit';
import { BenefitTy } from '~/types';
import { useSubscription } from '~/stores/subscription';

export const useDfBenefit = defineStore('df-benefit', () => {
  const quota = reactive({
    total: 0,
    used: 0,
  });
  const discount = ref(0);

  const subscription = useSubscription();

  const init = async () => {
    try {
      const res = await getMyDfBenefitsApi.fetch();
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message);
      }
      const entitlements = res.data?.entitlements ?? [];
      entitlements.forEach((item: any) => {
        if (item.type === BenefitTy.QUOTA) {
          quota.total = item.value;
          quota.used = item.used;
        } else if (item.type === BenefitTy.DISCOUNT) {
          discount.value = item.value;
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  watch(() => subscription.plan, init);

  return {
    quota,
    discount,
    init,
  };
});
